import type { UIMatch } from '@remix-run/react';
import { useMatches } from '@remix-run/react';
import type { FallbackNs, UseTranslationOptions } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import type { FlatNamespace, KeyPrefix } from 'i18next';
import { useMemo } from 'react';

export type HandleWithI18n = {
  i18n: string | string[];
};

// copied from react-i18next/helpers.d.ts
type $Tuple<T> = readonly [T?, ...T[]];

/**
 * Uses the route scope to determine the translation namespaces to use.
 * Will crawl up the route tree to find the closest i18n namespace and use it as the default namespace.
 * You can provide your own namespaces to use in addition to the namepsaces defined by the route.
 *
 * See https://react.i18next.com/latest/usetranslation-hook for more information on namespace behavior
 * @param additionalNamespaces
 * @param options
 */
export function useTranslationScope<
  Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
  KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(additionalNamespaces?: string | Array<string>, options?: UseTranslationOptions<KPrefix>) {
  // get the current route matches
  const matches = useMatches();

  const translationNamespaces = useMemo(() => {
    // Convert to array if it's a single string
    const userNamespaces = Array.isArray(additionalNamespaces)
      ? additionalNamespaces
      : [additionalNamespaces].filter(Boolean);

    // get the closest route match that has an i18n namespace defined to the
    // component calling this hook.
    const nearestI18nHandle = matches
      .slice()
      .reverse()
      .find((match) => isHandleWithI18n(match.handle)) as UIMatch<unknown, HandleWithI18n> | undefined;

    // Convert the route i18n namespace to an array
    const routeNamespaces = !nearestI18nHandle
      ? []
      : Array.isArray(nearestI18nHandle?.handle.i18n)
        ? nearestI18nHandle.handle.i18n
        : [nearestI18nHandle.handle.i18n];

    // Combine the route namespaces with the user provided namespaces
    return Array.from(new Set([...routeNamespaces, ...userNamespaces]));
  }, [matches, additionalNamespaces]);

  return useTranslation(translationNamespaces, options);
}

function isHandleWithI18n(handle: unknown): handle is HandleWithI18n {
  return (
    typeof handle === 'object' &&
    handle !== null &&
    'i18n' in handle &&
    (typeof handle.i18n === 'string' || Array.isArray(handle.i18n))
  );
}
